import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

// roles: ['ADMIN', 'CRO_CRA', 'CRO_QC', 'CRO_EP', 'CP_EP', 'CP_QC', 'LAB_EP']

let routes = [
    {
        // will match everything
        path: '*',
        component: () => import('../views/404.vue'),
        meta: {
            noAuth: true,
            hideInMenu: true,
            title: '404',
        },
    },
    {
        path: '/login',
        name: 'Login',
        meta: {
            noAuth: true,
            hideInMenu: true,
            title: 'Login',
        },
        component: () => import(/* webpackChunkName: "dashboard" */ '../views/Authentication/Sign-In/Basic.vue'),
    },
    {
        path: '/403',
        name: '403',
        meta: {
            noAuth: true,
            hideInMenu: true,
            title: '403',
        },
        component: () => import(/* webpackChunkName: "dashboard" */ '../views/403.vue'),
    },
    {
        path: '/coming-soon',
        name: 'comingSoon',
        layout: "dashboard",
        meta: {
            noAuth: true,
            hideInMenu: true,
            title: 'Coming Soon...',
        },
        component: () => import(/* webpackChunkName: "dashboard" */ '../views/ComingSoon.vue'),
    },
    {
        path: '/',
        name: 'Home',
        redirect: () => {
            if (!localStorage.getItem("HMC_ROLES") || !localStorage.getItem("HMC_USER")) {
                // router.replace("/login");
                return "/login"
            }

            const roles = localStorage.getItem("HMC_ROLES").split(',');
            console.log(roles);

            if(roles.includes('ADMIN')){
                return '/dashboard';
            } else if(roles.includes('CRO_CRA')){
                return '/dashboard';
            } else if(roles.includes('CP_EP')){
                return '/site/cp-entry-ep';
            } else if(roles.includes('CP_QC')){
                return '/site/cp-patient-list-qc';
            } else if(roles.includes('LAB_EP')){
                return '/laboratory/lab-entry';
            } else if(roles.includes('CRO_EP')){
                return '/CRO/CRO-to-be-entered-ep';
            } else if(roles.includes('CRO_QC')){
                return '/CRO/CRO-patient-list-qc';
            }


            return '/403';
        },
        meta: {
            hideInMenu: true,
        },
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        layout: "dashboard",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        meta: {
            title: 'Dashboard',
            icon: 'dashboard',
            roles: [
                "ADMIN",
                "CRO_CRA",
            ],
        },
        component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboards/Default.vue'),
    },
    {
        path: '/basic-manage',
        name: 'basicManage',
        layout: "dashboard",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        meta: {
            title: 'Basic Manage',
            icon: 'hdd',
            roles: [
                "ADMIN",
                "CRO_CRA",
            ],
        },
        component: () => import(/* webpackChunkName: "dashboard" */ '../layouts/Block.vue'),
        children: [
            {
                path: 'trial-manage',
                name: 'trialManage',
                layout: "dashboard",
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                meta: {
                    title: 'Trial',
                },
                component: () => import(/* webpackChunkName: "dashboard" */ '../layouts/Block.vue'),
                children: [
                    {
                        path: 'add-trial',
                        name: 'addTrial',
                        layout: "dashboard",
                        // route level code-splitting
                        // this generates a separate chunk (about.[hash].js) for this route
                        // which is lazy-loaded when the route is visited.
                        meta: {
                            title: 'Add Trial',
                        },
                        component: () => import(/* webpackChunkName: "dashboard" */ '../views/Project/New.vue'),
                    },
                    {
                        path: 'trial-list',
                        name: 'trialList',
                        layout: "dashboard",
                        // route level code-splitting
                        // this generates a separate chunk (about.[hash].js) for this route
                        // which is lazy-loaded when the route is visited.
                        meta: {
                            title: 'Trial List',
                            // layoutClass: 'layout-profile',
                        },
                        component: () => import(/* webpackChunkName: "dashboard" */ '../views/Project/List.vue'),
                    },
                    {
                        path: 'update-trial-info/:id',
                        name: 'updateTrialInfo',
                        layout: "dashboard",
                        // route level code-splitting
                        // this generates a separate chunk (about.[hash].js) for this route
                        // which is lazy-loaded when the route is visited.
                        meta: {
                            replaceName: 'trialList',
                            addBreadcrumbs: [
                                {
                                    title: 'Trial List',
                                    path: 'trial-list',
                                },
                            ],
                            title: 'Update Trial Info',
                            hideInMenu: true,
                        },
                        component: () => import(/* webpackChunkName: "dashboard" */ '../views/Project/UpdateInfo.vue'),
                    },
                ],
            },
            {
                path: 'site-manage',
                name: 'siteManage',
                layout: "dashboard",
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                meta: {
                    title: 'Site',
                },
                component: () => import(/* webpackChunkName: "dashboard" */ '../layouts/Block.vue'),
                children: [
                    {
                        path: 'add-site',
                        name: 'addSite',
                        layout: "dashboard",
                        // route level code-splitting
                        // this generates a separate chunk (about.[hash].js) for this route
                        // which is lazy-loaded when the route is visited.
                        meta: {
                            title: 'Add Site',
                        },
                        component: () => import(/* webpackChunkName: "dashboard" */ '../views/ClinicalPlace/New.vue'),
                    },
                    {
                        path: 'site-list',
                        name: 'siteList',
                        layout: "dashboard",
                        // route level code-splitting
                        // this generates a separate chunk (about.[hash].js) for this route
                        // which is lazy-loaded when the route is visited.
                        meta: {
                            title: 'Site List',
                            // layoutClass: 'layout-profile',
                        },
                        component: () => import(/* webpackChunkName: "dashboard" */ '../views/ClinicalPlace/List.vue'),
                    },
                    {
                        path: 'update-site/:id',
                        name: 'updateSite',
                        layout: "dashboard",
                        // route level code-splitting
                        // this generates a separate chunk (about.[hash].js) for this route
                        // which is lazy-loaded when the route is visited.
                        meta: {
                            replaceName: 'siteList',
                            addBreadcrumbs: [
                                {
                                    title: 'Site List',
                                    path: 'site-list',
                                },
                            ],
                            hideInMenu: true,
                            title: 'Update Site',
                        },
                        component: () => import(/* webpackChunkName: "dashboard" */ '../views/ClinicalPlace/Update.vue'),
                    },
                ],
            },
            {
                path: 'laboratory-manage',
                name: 'laboratoryManage',
                layout: "dashboard",
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                meta: {
                    title: 'Laboratory',
                },
                component: () => import(/* webpackChunkName: "dashboard" */ '../layouts/Block.vue'),
                children: [
                    {
                        path: 'add-laboratory',
                        name: 'addLaboratory',
                        layout: "dashboard",
                        // route level code-splitting
                        // this generates a separate chunk (about.[hash].js) for this route
                        // which is lazy-loaded when the route is visited.
                        meta: {
                            title: 'Add Laboratory',
                        },
                        component: () => import(/* webpackChunkName: "dashboard" */ '../views/Laboratory/New.vue'),
                    },
                    {
                        path: 'laboratory-list',
                        name: 'laboratoryList',
                        layout: "dashboard",
                        // route level code-splitting
                        // this generates a separate chunk (about.[hash].js) for this route
                        // which is lazy-loaded when the route is visited.
                        meta: {
                            title: 'Laboratory List',
                            // layoutClass: 'layout-profile',
                        },
                        component: () => import(/* webpackChunkName: "dashboard" */ '../views/Laboratory/List.vue'),
                    },
                    {
                        path: 'update-laboratory/:id',
                        name: 'updateLaboratory',
                        layout: "dashboard",
                        // route level code-splitting
                        // this generates a separate chunk (about.[hash].js) for this route
                        // which is lazy-loaded when the route is visited.
                        meta: {
                            replaceName: 'laboratoryList',
                            addBreadcrumbs: [
                                {
                                    title: 'Laboratory List',
                                    path: 'laboratory-list',
                                },
                            ],
                            title: 'Update Laboratory',
                            hideInMenu: true,
                        },
                        component: () => import(/* webpackChunkName: "dashboard" */ '../views/Laboratory/Update.vue'),
                    },
                ],
            },
            {
                path: 'device-manage',
                name: 'deviceManage',
                layout: "dashboard",
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                meta: {
                    title: 'Device',
                },
                component: () => import(/* webpackChunkName: "dashboard" */ '../layouts/Block.vue'),
                children: [
                    {
                        path: 'add-device',
                        name: 'addDevice',
                        layout: "dashboard",
                        // route level code-splitting
                        // this generates a separate chunk (about.[hash].js) for this route
                        // which is lazy-loaded when the route is visited.
                        meta: {
                            title: 'Add Device',
                        },
                        // component: () => import(/* webpackChunkName: "dashboard" */ '../views/ComingSoon.vue'),
                        component: () => import(/* webpackChunkName: "dashboard" */ '../views/Product/New.vue'),
                    },
                    {
                        path: 'device-list',
                        name: 'deviceList',
                        layout: "dashboard",
                        // route level code-splitting
                        // this generates a separate chunk (about.[hash].js) for this route
                        // which is lazy-loaded when the route is visited.
                        meta: {
                            title: 'Device List',
                            // layoutClass: 'layout-profile',
                        },
                        component: () => import(/* webpackChunkName: "dashboard" */ '../views/Product/List.vue'),
                    },
                    {
                        path: 'update-device/:id',
                        name: 'updateDevice',
                        layout: "dashboard",
                        // route level code-splitting
                        // this generates a separate chunk (about.[hash].js) for this route
                        // which is lazy-loaded when the route is visited.
                        meta: {
                            replaceName: 'deviceList',
                            addBreadcrumbs: [
                                {
                                    title: 'Device List',
                                    path: 'device-list',
                                },
                            ],
                            title: 'Update Device',
                            hideInMenu: true,
                        },
                        // component: () => import(/* webpackChunkName: "dashboard" */ '../views/ComingSoon.vue'),
                        component: () => import(/* webpackChunkName: "dashboard" */ '../views/Product/Update.vue'),
                    },
                ],
            },
            {
                path: 'patient-manage',
                name: 'patientManage',
                layout: "dashboard",
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                meta: {
                    title: 'Patient',
                },
                component: () => import(/* webpackChunkName: "dashboard" */ '../layouts/Block.vue'),
                children: [
                    {
                        path: 'add-patient',
                        name: 'addPatient',
                        layout: "dashboard",
                        // route level code-splitting
                        // this generates a separate chunk (about.[hash].js) for this route
                        // which is lazy-loaded when the route is visited.
                        meta: {
                            title: 'Add Patient',
                        },
                        // component: () => import(/* webpackChunkName: "dashboard" */ '../views/ComingSoon.vue'),
                        component: () => import(/* webpackChunkName: "dashboard" */ '../views/Patient/New.vue'),
                    },
                    {
                        path: 'patient-list',
                        name: 'patientList',
                        layout: "dashboard",
                        // route level code-splitting
                        // this generates a separate chunk (about.[hash].js) for this route
                        // which is lazy-loaded when the route is visited.
                        meta: {
                            title: 'Patient List',
                            // layoutClass: 'layout-profile',
                        },
                        component: () => import(/* webpackChunkName: "dashboard" */ '../views/Patient/List.vue'),
                    },
                    {
                        path: 'patient-log/:id',
                        name: 'patientLog',
                        layout: "dashboard",
                        // route level code-splitting
                        // this generates a separate chunk (about.[hash].js) for this route
                        // which is lazy-loaded when the route is visited.
                        meta: {
                            replaceName: 'patientList',
                            addBreadcrumbs: [
                                {
                                    title: 'Patient List',
                                    path: 'patient-list',
                                },
                            ],
                            hideInMenu: true,
                            title: 'Patient Log',
                            // layoutClass: 'layout-profile',
                        },
                        component: () => import(/* webpackChunkName: "dashboard" */ '../views/Patient/Log.vue'),
                    },
                    // {
                    //     path: 'update-patient/:id',
                    //     name: 'updatePatient',
                    //     layout: "dashboard",
                    //     // route level code-splitting
                    //     // this generates a separate chunk (about.[hash].js) for this route
                    //     // which is lazy-loaded when the route is visited.
                    //     meta: {
                    //         replaceName: 'patientList',
                    //         addBreadcrumbs: [
                    //             {
                    //                 title: 'Patient List',
                    //                 path: 'patient-list',
                    //             },
                    //         ],
                    //         title: 'Update Patient',
                    //         hideInMenu: true,
                    //     },
                    //     component: () => import(/* webpackChunkName: "dashboard" */ '../views/ComingSoon.vue'),
                    //     // component: () => import(/* webpackChunkName: "dashboard" */ '../views/Patient/Update.vue'),
                    // },
                ],
            },

            {
                path: 'client-manage',
                name: 'clientManage',
                layout: "dashboard",
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                meta: {
                    title: 'Client',
                },
                component: () => import(/* webpackChunkName: "dashboard" */ '../layouts/Block.vue'),
                children: [
                    {
                        path: 'add-client',
                        name: 'addClient',
                        layout: "dashboard",
                        // route level code-splitting
                        // this generates a separate chunk (about.[hash].js) for this route
                        // which is lazy-loaded when the route is visited.
                        meta: {
                            title: 'Add Client',
                        },
                        // component: () => import(/* webpackChunkName: "dashboard" */ '../views/ComingSoon.vue'),
                        component: () => import(/* webpackChunkName: "dashboard" */ '../views/Client/New.vue'),
                    },
                    {
                        path: 'client-list',
                        name: 'clientList',
                        layout: "dashboard",
                        // route level code-splitting
                        // this generates a separate chunk (about.[hash].js) for this route
                        // which is lazy-loaded when the route is visited.
                        meta: {
                            title: 'Client List',
                            // layoutClass: 'layout-profile',
                        },
                        component: () => import(/* webpackChunkName: "dashboard" */ '../views/Client/List.vue'),
                    },
                    {
                        path: 'update-client/:id',
                        name: 'updateClient',
                        layout: "dashboard",
                        // route level code-splitting
                        // this generates a separate chunk (about.[hash].js) for this route
                        // which is lazy-loaded when the route is visited.
                        meta: {
                            replaceName: 'clientList',
                            addBreadcrumbs: [
                                {
                                    title: 'Client List',
                                    path: 'client-list',
                                },
                            ],
                            title: 'Update Client',
                            hideInMenu: true,
                        },
                        // component: () => import(/* webpackChunkName: "dashboard" */ '../views/ComingSoon.vue'),
                        component: () => import(/* webpackChunkName: "dashboard" */ '../views/Client/Update.vue'),
                    },
                ],
            },
        ],
    },
    {
        path: '/site',
        name: 'site',
        layout: "dashboard",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        meta: {
            title: 'Site',
            icon: 'bulb',
            roles: [
                "CP_EP",
                "CP_QC",
            ],
        },
        component: () => import(/* webpackChunkName: "dashboard" */ '../layouts/Block.vue'),
        children: [
            {
                path: 'cp-entry-ep',
                name: 'cpEntry4CP_EP',
                layout: "dashboard",
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                meta: {
                    title: 'Entry information',
                    roles: [
                        "CP_EP",
                    ],
                },
                component: () => import(/* webpackChunkName: "dashboard" */ '../views/ClinicalPlace/Patient/Entry4CP_EP.vue'),
                // component: () => import(/* webpackChunkName: "dashboard" */ '../views/ComingSoon.vue'),
            },
            {
                path: 'cp-entered-list-ep',
                name: 'cpEnteredList4CP_EP',
                layout: "dashboard",
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                meta: {
                    title: 'Entered List',
                    roles: [
                        "CP_EP",
                    ],
                },
                component: () => import(/* webpackChunkName: "dashboard" */ '../views/ClinicalPlace/Patient/List4CP_EP.vue'),
            },
            {
                path: 'cp-patient-list-qc',
                name: 'cpPatientList4CP_QC',
                layout: "dashboard",
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                meta: {
                    title: 'Patient List',
                    roles: [
                        "CP_QC",
                    ],
                },
                component: () => import(/* webpackChunkName: "dashboard" */ '../views/ClinicalPlace/Patient/List4CP_QC.vue'),
            },
        ],
    },
    {
        path: '/laboratory',
        name: 'laboratory',
        layout: "dashboard",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        meta: {
            title: 'Laboratory',
            icon: 'experiment',
            roles: [
                "LAB_EP",
            ],
        },
        component: () => import(/* webpackChunkName: "dashboard" */ '../layouts/Block.vue'),
        children: [
            {
                path: 'lab-entry',
                name: 'labEntry',
                layout: "dashboard",
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                meta: {
                    title: 'Entry information',
                },
                component: () => import(/* webpackChunkName: "dashboard" */ '../views/Laboratory/Patient/Entry4LAB_EP.vue'),
                // component: () => import(/* webpackChunkName: "dashboard" */ '../views/ComingSoon.vue'),
            },
            {
                path: 'lab-patient-list-ep',
                name: 'labPatientList4LAB_EP',
                layout: "dashboard",
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                meta: {
                    title: 'Patient List',
                },
                component: () => import(/* webpackChunkName: "dashboard" */ '../views/Laboratory/Patient/List4LAB_EP.vue'),
            },
        ],
    },
    {
        path: '/CRO',
        name: 'cro',
        layout: "dashboard",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        meta: {
            title: 'CRO',
            icon: 'rocket',
            // icon: 'home',
            roles: [
                "CRO_EP",
                "CRO_QC",
            ],
        },
        component: () => import(/* webpackChunkName: "dashboard" */ '../layouts/Block.vue'),
        children: [
            {
                path: 'CRO-to-be-entered-ep',
                name: 'croToBeEntered4CRO_EP',
                layout: "dashboard",
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                meta: {
                    title: 'To Be Entered',
                    roles: [
                        "CRO_EP",
                    ],
                },
                component: () => import(/* webpackChunkName: "dashboard" */ '../views/Cro/Patient/ToBeEntryList4CRO_EP.vue'),
            },
            {
                path: 'CRO-entry-ep/:id',
                name: 'croEntry4CRO_EP',
                layout: "dashboard",
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                meta: {
                    replaceName: 'croToBeEntered4CRO_EP',
                    addBreadcrumbs: [
                        {
                            title: 'To Be Entered',
                            path: 'CRO-to-be-entered-ep',
                        },
                    ],
                    virtualParent: true,
                    hideInMenu: true,
                    title: 'Entry information',
                    roles: [
                        "CRO_EP",
                    ],
                },
                // component: () => import(/* webpackChunkName: "dashboard" */ '../views/ComingSoon.vue'),
                component: () => import(/* webpackChunkName: "dashboard" */ '../views/Cro/Patient/Entry4CRO_EP.vue'),
            },
            {
                path: 'CRO-entered-list-ep',
                name: 'croEnteredList4CRO_EP',
                layout: "dashboard",
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                meta: {
                    title: 'Entered List',
                    roles: [
                        "CRO_EP",
                    ],
                },
                component: () => import(/* webpackChunkName: "dashboard" */ '../views/Cro/Patient/List4CRO_EP.vue'),
            },
            {
                path: 'CRO-patient-list-qc',
                name: 'croPatientList4CRO_QC',
                layout: "dashboard",
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                meta: {
                    title: 'Patient List',
                    roles: [
                        "CRO_QC",
                    ],
                },
                component: () => import(/* webpackChunkName: "dashboard" */ '../views/Cro/Patient/List4CRO_QC.vue'),
            },
        ],
    },
    {
        path: '/settings',
        name: 'settings',
        layout: "dashboard",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        meta: {
            title: 'Settings',
            icon: 'setting',
            roles: [
                "ADMIN",
                "CRO_CRA",
            ],
        },
        component: () => import(/* webpackChunkName: "dashboard" */ '../layouts/Block.vue'),
        children: [
            {
                path: 'user-setting',
                name: 'userSetting',
                layout: "dashboard",
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                meta: {
                    title: 'User',
                },
                component: () => import(/* webpackChunkName: "dashboard" */ '../layouts/Block.vue'),
                children: [
                    {
                        path: 'user-setting-add',
                        name: 'userSettingAdd',
                        layout: "dashboard",
                        // route level code-splitting
                        // this generates a separate chunk (about.[hash].js) for this route
                        // which is lazy-loaded when the route is visited.
                        meta: {
                            title: 'New User',
                        },
                        component: () => import(/* webpackChunkName: "dashboard" */ '../views/Users/New.vue'),
                    },
                    {
                        path: 'user-setting-list',
                        name: 'userSettingList',
                        layout: "dashboard",
                        // route level code-splitting
                        // this generates a separate chunk (about.[hash].js) for this route
                        // which is lazy-loaded when the route is visited.
                        meta: {
                            title: 'User List',
                        },
                        component: () => import(/* webpackChunkName: "dashboard" */ '../views/Users/List.vue'),
                    },
                    {
                        path: 'user-setting-update/:id',
                        name: 'userSettingUpdate',
                        layout: "dashboard",
                        // route level code-splitting
                        // this generates a separate chunk (about.[hash].js) for this route
                        // which is lazy-loaded when the route is visited.
                        meta: {
                            replaceName: 'userSettingList',
                            addBreadcrumbs: [
                                {
                                    title: 'User List',
                                    path: 'user-setting-list',
                                },
                            ],
                            hideInMenu: true,
                            title: 'Update User',
                        },
                        component: () => import(/* webpackChunkName: "dashboard" */ '../views/Users/Update.vue'),
                    },
                ],
            },
        ],
    },
]

routes.forEach((e) => {
    if (e.meta) {
        e.meta.sidebarMap = [e.name];
        e.meta.breadcrumbs = [e.meta.title];
        if(e.meta.addBreadcrumbs){
            e.meta.breadcrumbs = [].concat(e.meta.addBreadcrumbs.map(t=>{return {...t, path: t.path}})).concat([e.meta.title]);
        }else{
            e.meta.breadcrumbs = [e.meta.title];
        }
        e.meta.fullPath = e.path;
        if (e.children) {
            e.children.forEach((e2) => {
                if (e2.meta) {
                    e2.meta.sidebarMap = e2.meta.replaceName ? [e.name,e2.meta.replaceName, e2.name] : [e.name, e2.name];
                    e2.meta.breadcrumbs = [e.meta.title, e2.meta.title];
                    if(e2.meta.addBreadcrumbs){
                        e2.meta.breadcrumbs = [e.meta.title].concat(e2.meta.addBreadcrumbs.map(t=>{return {...t, path: e.path + '/' + t.path}})).concat([e2.meta.title]);
                    }else{
                        e2.meta.breadcrumbs = [e.meta.title, e2.meta.title];
                    }
                    e2.meta.fullPath = e.path + '/' + e2.path;
                    if (e2.children) {
                        e2.children.forEach((e3) => {
                            if (e3.meta) {
                                e3.meta.sidebarMap = [e.name, e2.name, e3.meta.replaceName ? e3.meta.replaceName : e3.name];
                                if(e3.meta.addBreadcrumbs){
                                    e3.meta.breadcrumbs = [e.meta.title, e2.meta.title].concat(e3.meta.addBreadcrumbs.map(t=>{return {...t, path: e.path + '/' + e2.path + '/' + t.path}})).concat([e3.meta.title]);
                                }else{
                                    e3.meta.breadcrumbs = [e.meta.title, e2.meta.title, e3.meta.title];
                                }
                                e3.meta.fullPath = e.path + '/' + e2.path + '/' + e3.path;
                            }
                        });
                    }
                }
            });
        }
    }
});


// Adding layout property from each route to the meta
// object so it can be accessed later.
function addLayoutToRoute(route, parentLayout = "default") {
    route.meta = route.meta || {};
    route.meta.layout = route.layout || parentLayout;

    if (route.children) {
        route.children = route.children.map((childRoute) => addLayoutToRoute(childRoute, route.meta.layout));
    }
    return route;
}

routes = routes.map((route) => addLayoutToRoute(route));

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (to.hash) {
            return {
                selector: to.hash,
                behavior: 'smooth',
            }
        }
        return {
            x: 0,
            y: 0,
            behavior: 'smooth',
        }
    }
})
const appConfig = require("../../public/config");
router.beforeEach((to, from, next) => {
    // 拦截权限
    if (!localStorage.getItem("HMC_ROLES") || !localStorage.getItem("HMC_USER")) {
        // next("/login");

        if (to.meta && to.meta.noAuth) {
            next();
        } else {
            // console.log(to);

            const IS_LOGOUT = localStorage.getItem("IS_LOGOUT");
            localStorage.removeItem("IS_LOGOUT");
            if(IS_LOGOUT){
                router.replace("/login");
            }else{
                router.replace("/login?from=" + to.fullPath + "&showErr=true");
            }
        }


    } else {
        const roles = localStorage.getItem("HMC_ROLES").split(',');
        const matcheds = to.matched;
        // console.log(matcheds);

        let hasRoles = false;
        let hasMeta = false;
        if (to.meta && to.meta.noAuth) {
            if (!hasMeta) {
                hasMeta = true;
            }
            hasRoles = true;
            // console.log("hasRoles4", hasRoles);
        } else {
            for (let i = matcheds.length - 1; i >= 0; i--) {
                if (matcheds[i].meta && matcheds[i].meta.roles) {
                    if (!hasMeta) {
                        hasMeta = true;
                    }
                    const _hasRoles = matcheds[i].meta.roles.filter(item => {
                        return roles.includes(item)
                    });
                    if (_hasRoles && _hasRoles.length > 0) {
                        hasRoles = true;
                        break;
                    } else {
                        hasRoles = false;
                        break;
                    }
                }
            }
            // console.log("hasRoles3", hasRoles);
        }

        // console.log("hasRoles", hasRoles);
        if (!hasMeta) {
            hasRoles = true;
        }

        // console.log("hasRoles2", hasRoles);


        if (hasRoles) {
            /* 路由发生变化修改页面title */
            if (to.meta.title) {
                document.title = to.meta.title + ' - ' + appConfig.app.title;
            } else {
                document.title = appConfig.app.title;
            }
            next();
        } else {
            router.replace("/403");
        }
    }

})
router.afterEach((to, from)=>{

    window.$eventBus.$emit('onClickBreadcrumbs');
});

export default router
