<!--
	This is the main page of the application, the layout component is used here,
	and the router-view is passed to it.
	Layout component is dynamically declared based on the layout for each route,
	specified in routes list router/index.js .
 -->

<template>
  <a-config-provider
      :transformCellText="tableTransformCellText">
    <div id="app">
      <component :is="layout">
        <router-view/>
      </component>
    </div>
  </a-config-provider>
</template>

<script>

export default ({
  computed: {
    // Sets components name based on current route's specified layout, defaults to
    // <layout-default></layout-default> component.
    layout() {
      return "layout-" + (this.$route.meta.layout || "default").toLowerCase();
    }
  },
  created(){

  },
  methods: {
    tableTransformCellText(content, column, record, index) {
      const h = this.$createElement;
      const _text = content.text + '';
      return _text === '' || _text === 'undefined' || _text === 'null' ? h('span', {
        style: {
          color: '#CCC',
        },
      }, '-') : content.text;

    },
  }
})

</script>

<style lang="scss">
</style>
