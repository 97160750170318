<template>

  <!-- Main Sidebar -->
  <a-layout-sider
      collapsible
      class="sider-primary"
      breakpoint="lg"
      collapsed-width="0"
      width="250px"
      :collapsed="sidebarCollapsed"
      @collapse="$emit('toggleSidebar', ! sidebarCollapsed)"
      :trigger="null"
      :class="['ant-layout-sider-' + sidebarColor, 'ant-layout-sider-' + sidebarTheme]"
      theme="light"
      :style="{ backgroundColor: 'transparent',}">
    <div class="brand"><img src="/images/logo-ct-black.png" alt="Logo"> <span>{{ brandName }}</span></div>
    <hr>

    <!-- Sidebar Navigation Menu -->
    <a-menu theme="light" mode="inline"
            :selected-keys="openKeys"
            :open-keys="openKeys"
            @click="onSelectMenuItem"
            @openChange="onOpenChange">
      <!-- 一级菜单 -->
      <template v-for="(menu, index) in menus">
        <template v-if="menu.meta && menu.path && menu.name && !menu.needDelete">

          <a-menu-item class="menu-item-header" v-if="menu.meta.groupName || menu.meta.groupHr">
            <hr :class="menu.meta.groupName ? 'mt-5' : 'mb-0 mt-0'" v-if="menu.meta.groupHr">
            <span v-if="menu.meta.groupName">{{ menu.meta.groupName }}</span>
          </a-menu-item>

          <template v-if="menu.children && menu.children.length > 0">
            <!-- 二级菜单 -->
            <a-sub-menu :key="menu.name" style="padding: 0;">
              <span slot="title" v-if="menu.meta">
                <span class="icon" v-if="menu.meta.icon">
                  <a-icon :type="menu.meta.icon" theme="filled" class="m-0"/>
                </span>
                <span class="label">{{ menu.meta.title }}</span>
              </span>

              <a-menu-item-group>
                <template v-for="(menu_2, index_2) in menu.children">
                  <template v-if="menu_2.meta && menu_2.path && menu_2.name && !menu_2.needDelete">
                    <template v-if="menu_2.children && menu_2.children.length>0">
                      <!-- 三级菜单 -->
                      <a-sub-menu :key="menu_2.name" style="padding: 0;" :title="menu_2.meta ? menu_2.meta.title : '-'">
                        <a-menu-item-group>
                          <template v-for="(menu_3, index_3) in menu_2.children">
                            <template v-if="menu_3.meta && menu_3.path && menu_3.name && !menu_3.needDelete">
                              <a-menu-item :key="menu_3.name">
                                <router-link :to="menu_3.meta.fullPath">
                                  <span class="label" :style="menu_3.meta && openKeys.length === 3 && openKeys[2] === menu_3.name && isNameReplaced ? 'color: #141414 !important;' : ''">{{ menu_3.meta ? menu_3.meta.title : '-' }}</span>
                                </router-link>
                              </a-menu-item>
                            </template>
                          </template>
                        </a-menu-item-group>
                      </a-sub-menu>

                    </template>
                    <template v-else>
                      <a-menu-item :key="menu_2.name" v-if="menu_2.meta">
                        <router-link :to="menu_2.meta.fullPath">
                          <span class="label" :style="(menu_2.meta && openKeys.length === 2 && openKeys[1] === menu_2.name && isNameReplaced) || (menu_2.meta && openKeys.length === 3 && openKeys[1] === menu_2.name && virtualParent) ? 'color: #141414 !important;' : ''">{{ menu_2.meta.title }}</span>
                        </router-link>
                      </a-menu-item>
                    </template>
                  </template>


                </template>

              </a-menu-item-group>


            </a-sub-menu>
          </template>
          <template v-else>
            <a-menu-item :key="menu.name" v-if="menu.meta">
              <router-link :to="menu.meta.fullPath">
      						<span class="icon" v-if="menu.meta && menu.meta.icon">
      							<a-icon :type="menu.meta.icon" theme="filled" class="m-0"/>
      						</span>
                <span class="label"  :style="menu.meta  && openKeys.length === 1 && openKeys[0] === menu.name && isNameReplaced ? 'color: #141414 !important;' : ''">{{ menu.meta ? menu.meta.title : '-' }}</span>
              </router-link>
            </a-menu-item>
          </template>

        </template>
      </template>

    </a-menu>
    <!-- / Sidebar Navigation Menu -->

    <!-- Sidebar Footer -->
    <div class="aside-footer">
    </div>
    <!-- / Sidebar Footer -->

  </a-layout-sider>
  <!-- / Main Sidebar -->

</template>

<script>
const appConfig = require("../../../public/config");

export default ({
  props: {
    // Sidebar collapsed status.
    sidebarCollapsed: {
      type: Boolean,
      default: false,
    },

    // Main sidebar color.
    sidebarColor: {
      type: String,
      default: "primary",
    },

    // Main sidebar theme : light, white, dark.
    sidebarTheme: {
      type: String,
      default: "light",
    },
  },
  data() {
    return {
      rootSubmenuKeys: [],
      openKeys: this.$route.meta.sidebarMap,
      brandName: appConfig.app.name,
      menus: [],
      isNameReplaced: !!this.$route.meta.replaceName,
      virtualParent: this.$route.meta.virtualParent,
    }
  },
  // beforeCreate(){
  //   console.log("DashboardSidebar.vue beforeCreate()");
  //
  //   console.log(this.$router.options.routes);
  //
  //
  //   this.menus = [...this.$router.options.routes];
  //
  //   console.log("this.menus", this.menus);
  //
  // },
  beforeDestroy(){
    this.$eventBus.$off('onClickBreadcrumbs');
  },
  created() {
    const that = this;
    this.$eventBus.$on('onClickBreadcrumbs', ()=>{
      console.log("aaa");
      that.openKeys = that.$route.meta.sidebarMap;
      that.isNameReplaced = !!that.$route.meta.replaceName;
      that.virtualParent = that.$route.meta.virtualParent;
    });


    const _menus = JSON.parse(JSON.stringify(this.$router.options.routes));

    // 根据角色显示菜单
    const HMC_ROLES = localStorage.getItem("HMC_ROLES");
    const HMS_ROLES_ARRAY = HMC_ROLES.split(',');


    _menus.forEach((e) => {
      if (e.meta && !e.meta.hideInMenu) {
        if (e.meta.roles && e.meta.roles.length > 0) {
          const hasRoles = e.meta.roles.filter(item => {
            return HMS_ROLES_ARRAY.includes(item)
          });
          if (!hasRoles || hasRoles.length <= 0) {
            e.needDelete = true;
          }
        }

        if (!e.needDelete && e.children && e.children.length >0) {
          e.children.forEach((e2) => {
            if (e2.meta && !e2.meta.hideInMenu) {
              if (e2.meta.roles && e2.meta.roles.length > 0) {
                const hasRoles = e2.meta.roles.filter(item => {
                  return HMS_ROLES_ARRAY.includes(item)
                });
                if (!hasRoles || hasRoles.length <= 0) {
                  e2.needDelete = true;
                }
              }

              if (!e2.needDelete && e2.children && e2.children.length >0) {
                e2.children.forEach((e3) => {
                  if (e3.meta && !e3.meta.hideInMenu) {
                    if (e3.meta.roles && e3.meta.roles.length > 0) {
                      const hasRoles = e3.meta.roles.filter(item => {
                        return HMS_ROLES_ARRAY.includes(item)
                      });
                      if (!hasRoles || hasRoles.length <= 0) {
                        e3.needDelete = true;
                      }
                    }
                  } else {
                    e3.needDelete = true;
                  }
                });
              }
            } else {
              e2.needDelete = true;
            }
          });
        }
      } else {
        e.needDelete = true;
      }
    });

    this.menus = [..._menus];

    // 根菜单列表
    let _rootSubmenuKeys = [];
    this.menus.forEach((e) => {
      _rootSubmenuKeys.push(e.name);
    });
    this.rootSubmenuKeys = _rootSubmenuKeys;
    // console.log("this.menus", this.menus);
    // console.log("this.rootSubmenuKeys", this.rootSubmenuKeys);


    // this.openKeys = [this.$route.meta.sidebarMap[0]];
    console.log("this.openKeys", this.openKeys)
  },
  methods: {
    onSelectMenuItem(){
      this.openKeys = this.$route.meta.sidebarMap;
      this.isNameReplaced = !!this.$route.meta.replaceName;
      this.virtualParent = this.$route.meta.virtualParent;
    },
    onOpenChange(openKeys) {
      console.log("this.openKeys1", this.openKeys)
      console.log("openKeys", openKeys)
      const latestOpenKey = this.openKeys ? openKeys.find(key => this.openKeys.indexOf(key) === -1): [];
      console.log("latestOpenKey", latestOpenKey)

      if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
        this.openKeys = openKeys;
      } else {
        this.openKeys = latestOpenKey ? [latestOpenKey] : openKeys;
      }
      // console.log("this.openKeys", this.openKeys)
    },
  },
})

</script>
