// VueJS
import Vue from 'vue'

// Ant Design Vue
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';

import axiosUtil from './plugins/axios/index'
import formValidateSync from './plugins/form-validate-sync/index'

Vue.use(Antd);


// Photoswipe Gallery
import Photoswipe from 'vue-pswipe'
Vue.use(Photoswipe)

// Template Layouts
import DefaultLayout from './layouts/Default.vue'
import DashboardLayout from './layouts/Dashboard.vue'
import DashboardRTLLayout from './layouts/DashboardRTL.vue'

// Adding template layouts to the vue components.
Vue.component("layout-default", DefaultLayout);
Vue.component("layout-dashboard", DashboardLayout);
Vue.component("layout-dashboard-rtl", DashboardRTLLayout);

import WidgetLoading from './components/Widgets/WidgetLoading.vue'
Vue.component("a-loading", WidgetLoading);

Vue.prototype.$axios = axiosUtil
Vue.prototype.$fvs = formValidateSync
Vue.prototype.$eventBus = new Vue()
window.$eventBus = Vue.prototype.$eventBus

Vue.prototype.$getBase64=(file)=>{
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

Vue.prototype.$buildPageParam = (pagination, filters, sorter) => {
  const filtersParam = {};
  if (filters) {
    for (let key in filters) {
      filtersParam[key] = filters[key].join(',');
    }
  }
  const pageParam = {
    current: pagination.current,
    size: pagination.pageSize,
    filters: filtersParam,
    orders: sorter && sorter.columnKey ? [{column: sorter.columnKey, asc: sorter.order === 'ascend'}] : [],
  }
  return pageParam;
}

// Main application view
import App from './App.vue'

// Vue Router
import router from './router'

// App Styling
import './scss/app.scss';

Vue.config.productionTip = false

// Initialize Vue
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
