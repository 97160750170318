import Vue from 'vue'
import axios from 'axios'
import querystring from 'querystring'

axios.defaults.baseURL = '/api'
axios.defaults.headers.post['Content-Type'] = 'application/json;charset=utf-8'
axios.defaults.timeout = 600000

const post = ({
                url,
                data = {},
                tips = true,
                loading = false,
                loadingText = 'Loading...'
            }) => {
    return new Promise((resolve, reject) => {
        // console.log(Vue.prototype);
        let hideLoading;
        if (loading) {
            hideLoading = Vue.prototype.$message.loading(loadingText);
        }
        axios.post(url, data)
            .then(res => {
                // console.log(res)
                if (loading && hideLoading) hideLoading();
                if (res.data.code === 'SUCCESS' && res.data.success) {
                    resolve(res.data)
                } else {
                    console.error(res);
                    if (parseInt(res.data.code) === 100000 || res.data.code === '100000') {
                        localStorage.removeItem("HMC_ROLES");
                        localStorage.removeItem("HMC_USER");
                        window.location.reload();
                    } else {
                        if (tips) {

                            Vue.prototype.$message.error(res.data.msg + ' [' + res.data.code + ']');
                            // Vue.$notification.open({
                            //     class: 'ant-notification-warning',
                            //     message: res.data.msg + '<br/>[' + res.data.code + ']',
                            // });
                        }
                    }
                    resolve({
                        ...res.data,
                        success: false,
                    })
                }
            })
            .catch(err => {
                if (loading && hideLoading) hideLoading();
                console.error(err);
                Vue.prototype.$message.error('Request timed out');
                resolve({
                    success: false,
                    err: err,
                    msg: 'Request timed out',
                })
            });
    })
}


const batchPost = ({
                     urls,
                     tips = true,
                     loading = false,
                     loadingText = 'Loading...'
                 }) => {
    return new Promise((resolve, reject) => {
        // console.log(Vue.prototype);
        let hideLoading;
        if (loading) {
            hideLoading = Vue.prototype.$message.loading(loadingText);
        }

        let postedCount = 0;
        let successPostedCount = 0;
        let results = [];


        for (let i = 0; i < urls.length; i++) {
            const {url, data={},} = urls[i];


            axios.post(url, data)
                .then(res => {
                    // console.log(res)
                    if (loading && hideLoading) hideLoading();
                    if (res.data.code === 'SUCCESS' && res.data.success) {
                        // resolve(res.data)
                        successPostedCount++;
                        results[i] = res.data;
                    } else {
                        console.error(res);
                        if (parseInt(res.data.code) === 100000 || res.data.code === '100000') {
                            localStorage.removeItem("HMC_ROLES");
                            localStorage.removeItem("HMC_USER");
                            window.location.reload();
                        } else {
                            if (tips) {

                                Vue.prototype.$message.error(res.data.msg + ' [' + res.data.code + ']');
                                // Vue.$notification.open({
                                //     class: 'ant-notification-warning',
                                //     message: res.data.msg + '<br/>[' + res.data.code + ']',
                                // });
                            }
                        }
                        results[i] = {
                            ...res.data,
                            success: false,
                        };
                        // resolve({
                        //     ...res.data,
                        //     success: false,
                        // })
                    }
                    postedCount++;
                })
                .catch(err => {
                    console.error(err);
                    Vue.prototype.$message.error('Request timed out');
                    // resolve({
                    //     success: false,
                    //     err: err,
                    //     msg: 'Request timed out',
                    // })

                    results[i] = {
                        success: false,
                        err: err,
                        msg: 'Request timed out',
                    };
                    postedCount++;
                }).finally(() => {
                    if (postedCount === urls.length) {
                        resolve({
                            success: successPostedCount === urls.length,
                            body: results,
                        })
                        if (loading && hideLoading) hideLoading();
                    }
                });
        }


    })
}

export default {
    post,
    batchPost
}
