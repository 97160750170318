<template>
  <div>
    <a-spin v-if="state === 'loading'" size="large" class="mx-auto d-block my-100" />
    <div v-else-if="state === 'empty'" class="mx-auto d-block my-100" >
      <a-result title="" subTitle="No data">
        <template #icon>
          <a-icon type="container" theme="twoTone" />
        </template>
        <template #extra>
        </template>
      </a-result>
    </div>
    <div v-else-if="state === 'failed'" class="mx-auto d-block my-90" >
      <a-result title="" subTitle="Failed to load">
        <template #icon>
          <a-icon type="frown" theme="twoTone" />
        </template>
        <template #extra>
        </template>
      </a-result>
    </div>
    <slot v-else></slot>
  </div>
</template>

<script>
	export default ({
    props: {
      state: {
        type: String,
        default: "loading", // loading, empty, failed
      },
      spinSize: {
        type: String,
        default: "large",
      },
    },
		data(){
			return {
			} ;
		},
	})
</script>
