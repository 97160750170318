const validateFieldsAndScroll = (form) => {
    return new Promise((resolve, reject) => {
        if (null == form) {
            resolve(false);
            console.error("form is null");
            return;
        }

        form.validateFieldsAndScroll(async (err, values) => {
            if (err) {
                resolve(false);
                return;
            }
            resolve(values);
        });

    });
}
const validateFields = (form) => {
    return new Promise((resolve, reject) => {
        if (null == form) {
            resolve(false);
            console.error("form is null");
            return;
        }

        form.validateFields(async (err, values) => {
            if (err) {
                resolve(false);
                return;
            }
            resolve(values);
        });
    });
}

export default {
    validateFieldsAndScroll,
    validateFields
}
